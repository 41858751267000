<!--
   User: Liu Yin
   Date: 2020/4/15
   Description:预览资讯
 -->
<template>
  <article style="position: relative">
    <!-- <return-key @close="handleBack"></return-key> -->
    <!-- <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><span @click="handleBack">资讯专栏</span></el-breadcrumb-item>
        <el-breadcrumb-item>资讯详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->
    <section class="content-parent">
      <h2 class="detail-title" style="text-align: center">{{ rowData.inotitle }}</h2>
      <section class="module-block" style="display: flex;justify-content: center">
        <p class="keyword" style="margin-left: 20px">来源: {{ rowData.linkurl }}</p>
        <p class="keyword" style="margin-left: 20px">发布时间: {{ rowData.publishtime | formattingTime('YYYY-MM-DD HH:MM') }}</p>
        <p class="keyword" style="margin-left: 20px">点击量: {{ rowData.clicks }}</p>
      </section>
      <div class="dash-line"></div>
      <div class="module-block">
        <el-card>
          <section v-if="rowData.contentType===1" class="rich-text" v-html="rowData.content"></section>
          <a v-else class="href-a" @click="openNewWindow(rowData.linkurl)">{{ rowData.linkurl }}</a>
        </el-card>
      </div>
      <div class="dash-line" style="margin-bottom: 30px;"></div>
      <div style="font-size: 18px;font-weight: 800;">附件：</div>
      <p v-for="item in rowData.enclosure" :key="item.name" class="margintop">
        <a @click="downLoad(item)">{{ item.name }}</a>
      </p>
    </section>

  </article>
</template>

<script>
import { downloadFile } from '@/utils/tools'
export default {
  name: 'PolicyDetail',
  props: {
    // rowId: {
    //   type: Number,
    //   default: 0
    // }
  },
  data() {
    return {
      rowData: {},
      listAtt: [],
      rowId: null
    }
  },
  mounted() {
    if (this.$route.query && (this.$route.query.rowId)) {
      this.rowId = this.$route.query.rowId
      this.requestData()
    }
  },
  methods: {
    requestData() {
      this.toggleLoading(true)
      this.$get(this.urls.informationDetail, {}, [this.rowId]).then(result => {
        this.toggleLoading(false)
        if (result.code === 'SUCCESS') {
          this.rowData = result.data
          const data = result.data
          const enclosure = []
          data.enclosureName = data.enclosureName.split(',')
          data.enclosureUrl = data.enclosureUrl.split(',')
          data.enclosureName.forEach(function(item, index) {
            const att = {}
            att.name = item
            att.url = data.enclosureUrl[index]
            enclosure.push(att)
          })
          this.rowData.enclosure = enclosure
        } else {
          this.$message.error(result.msg)
        }
      }).catch(() => {
        this.toggleLoading(false)
      })
    },
    downLoad(data) {
      downloadFile(data.url, data.name)
    },
    openNewWindow(url) {
      this.toggleLoading(true)
      this.$get(this.urls.informationAddClick, {}, [this.rowData.inid]).then(result => {
        this.toggleLoading(false)
        if (result.code === 'SUCCESS') {
          window.open(url)
        } else {
          this.$message.error(result.msg)
        }
      }).catch(() => {
        this.toggleLoading(false)
      })
    },
    handleBack() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
  .content-parent{
    margin: 0px auto;
    padding: 20px 0;
    min-height: 800px;
    width: 860px;
    .module-block{
      margin-top: 25px;
    }
  }
    .keyword{
      color: grey;
      font-size: px2vw(18);
      text-align: center;
    }
  .href-a{
    cursor: pointer;
    color: green;
  }
  .href-a:hover{
    color: blue;
  }
  .back{
    position: fixed;
    left: 20px;
    font-size: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .back:hover{
    color: rgb(0,198,154);
  }
  .detail-title{
    font-size: 30px;
    margin-top: 20px;
    line-height: 44px;
  }
  .dash-line{
    border-top: grey dashed 1px;
    margin-top: 35px;
  }
  .margintop{
    margin-top: 10px;
    cursor: pointer;
    color: #61a1c7;
    font-size: 16px;
  }
  .image{
    width: 860px;
    height: 600px;
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
  }

</style>

<style lang="scss">
  .rich-text{
    p{
      margin-top: 10px;
      line-height: 30px;
      span{
        line-height: 30px;
        color: black;
      }

      img{
        width: 80%;
      }
    }
    .image{
        width: 80%;
      }
  }
  .breadcrumb{
width: 1280px;
padding: 20px 10px;
margin: 0 auto;
cursor: pointer;
}
</style>

